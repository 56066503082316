import { AppConfig } from './config';

export const removeWWWFromHostname = (hostname: string): string =>
  hostname.startsWith('www.') ? hostname.substr('www.'.length) : hostname;

export const getVytalAppLoginDeepLink = (appLoginToken: string): string => {
  const url = new URL(AppConfig.VYTAL_APP_LOGIN_DEEP_LINK);
  url.searchParams.set('token', appLoginToken);
  return url.toString();
};
