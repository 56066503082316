import React, { useRef } from 'react';
import Head from 'next/head';
import { VytalHeader } from './Welcome/VytalHeader';
import { LeftScreenDisplay } from './LeftScreenDisplay/LeftScreenDisplay';
import { ScrollToLayoutBottom } from './ScrollToLayoutBottom/ScrollToLayoutBottom';

export default function Layout({
  children,
  title,
}: {
  children: any;
  title: string;
}) {
  return (
    <div className="h-full">
      <Head>
        <title>{title || 'Vytal Web Login'}</title>
      </Head>
      {children}
    </div>
  );
}

type PageProps = {
  title: string;
  children: any;
  user: any | false;
  userHasActiveContainers?: boolean | undefined;
};

export const Page = ({
  title,
  children,
  user,
  userHasActiveContainers,
}: PageProps): JSX.Element => {
  // The lines below calculates whether the parent element has a vertical scrollbar or not
  const contentContainer = useRef<null | HTMLDivElement>(null);
  const bottomRef = useRef<null | HTMLDivElement>(null);

  return (
    <Layout title={title}>
      <div className="vytal-card">
        <div className="vytal-leftContainer">
          <LeftScreenDisplay
            isAuthenticated={!!user}
            userHasActiveContainers={userHasActiveContainers}
            usersUsedContainers={user?.container_transaction_count}
          />
        </div>
        <div className="vytal-body">
          <VytalHeader showLogoutButton={!!user} />
          <div className="vytal-content" ref={contentContainer}>
            <div className="vytal-content-inner">
              {children}
              <div ref={bottomRef}></div>
              <ScrollToLayoutBottom
                contentContainer={contentContainer}
                bottomRef={bottomRef}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
