import React, { useEffect, useState } from 'react';
import styles from './VytalHeader.module.css';
import { isInIframe } from '../../utils/iframe';
import {
  sendCloseIframeMessage,
  sendUserLoggedOutMessage,
} from '../../services/webSdkCommunicator';
import { useRouter } from 'next/router';
import VytalLogo from '../../images/svg/Vytal-logo-claim-de-black.svg';
import CloseSVG from '../../images/svg/close.svg';
import { useTranslation } from 'next-i18next';
import { LOCAL_STORAGE_KEYS } from '../../services/localStorage';
import { getWrappedLink } from '../../utils/routerHelper';
import TRANSLATION_FILES from '../../public/locales/constants';

export function VytalHeader({
  showLogoutButton = true,
}: {
  showLogoutButton: boolean;
}) {
  const router = useRouter();
  const { t } = useTranslation(TRANSLATION_FILES.common);

  // show close button if used in Web SDK with an iframe
  // do the check only on client side, so SSR is consistent with first render
  const [showCloseButton, setShowCloseButton] = useState(false);
  useEffect(() => {
    setShowCloseButton(isInIframe());
  }, []);

  const onClose = async () => {
    await sendCloseIframeMessage();
  };

  const onLogoutClick = async () => {
    if (localStorage) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_JWT);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.VYTAL_ENCRYPTED_REFRESH_TOKEN);
    }

    try {
      await sendUserLoggedOutMessage();
    } catch (e) {
      console.log('onLogoutClick sendUserLoggedOutMessage', e);
    }

    const form = document.getElementById('logout-form') as HTMLFormElement;
    form.submit();
  };

  // suppressHydrationWarning only works one level deep, problem will not go away, this is okay
  // in this case
  return (
    <div className="vytal-header" suppressHydrationWarning={true}>
      <div className="vytal-logo-small">
        <VytalLogo />
      </div>
      <form
        id="logout-form"
        method="POST"
        action={getWrappedLink('/welcome/logout', router.query)}
      ></form>
      <div className="flex flex-row items-center space-x-5">
        {showLogoutButton && (
          <a
            className="justify-end vytal-dark font-semibold"
            onClick={onLogoutClick}
          >
            {t('logOut').toUpperCase()}
          </a>
        )}
        {showCloseButton && (
          <div className={styles.closeButton} onClick={onClose}>
            <CloseSVG />
          </div>
        )}
      </div>
    </div>
  );
}
