import { whitelistedHostnames } from '../services/vytalWebSdk/whitelistedHostnames';
import { removeWWWFromHostname } from './url';

export const checkForInvalidSdkUse = () => {
  if (isInIframe() && !isHostnameValidForSdkUse(getParentUri().hostname)) {
    alert(
      `invalid Vytal Web SDK use: hostname of ${getParentUrl()} is not whitelisted`,
    );
  }
};

export const isInIframe = () =>
  typeof window !== 'undefined' && window.location !== window.parent.location;

export const isHostnameValidForSdkUse = (hostname: string): boolean => {
  const cleanedHostname = removeWWWFromHostname(hostname);
  console.log('isHostnameValidForSdkUse', { hostname, cleanedHostname });
  return whitelistedHostnames.includes(cleanedHostname);
};

export const getParentUri = (): URL => {
  const url = new URL(getParentUrl());
  return url;
};

export const getParentUrl = (): string =>
  window.location != window.parent.location
    ? document.referrer
    : document.location.href;
