// list of hostnames who can use the vytal web sdk
// note: hostnames and not origins are used to make this simpler,
//       www prefix is ignored
import { removeWWWFromHostname } from '../../utils/url';

export const whitelistedHostnames = (() =>
  [
    'local-restaurant.vytal.org',
    'restaurant-vytal.vercel.app',
    'restaurant-vytal-dev.vercel.app',
    'fake1.local',
    'fakerestaurant.com',
    'stage.mainz-liefert.de',
    'mainz-liefert.de',
    'mundfein.local',
    'test.mundfein.de',
    'mundfein.de',
    'boxbote.de',
    'boxbote-staging.de',
    'kitchen.lazy-ants.net',
    'lazy-ants.net',
    'kitchen-prelive.lazy-ants.net',
    'order.eurekantine.de',
    'lammbock-steinofen.de',
    'www.lammbock-steinofen.de',
    'bestellen.beetschwester.de',
    'muenster.greenpandabowls.de',
    'kassel.greenpandabowls.de',
    'www.royals-and-rice.de',
    'yuzuramen.de',
    'www.yuzuramen.de',
    'takeout.restaurant-acacia.de',
    'www.muenster-isst.de',
    'www.eatfair.de',
    'bestellen.kleiner-kiepenkerl.de',
    'www.kleiner-kiepenkerl.de',
    'www.frood.de',
    'frood.de',
    'frood.agentur-schroeder.com',
    'senvegan.foodstashop.de',
    'www.bibu-vegan-mobil.de',
    'bkkstreetfood.myhypersoftapp.den',
    'cucina-mamma-online.de',
    'www.schlemmer-pizza-fellbach.de',
    'www.pokkez.de',
    'staging.lehmuese.de',
    'lehmuese.de',
    'sushile.de',
    'split-test-1.web.app',
    'split-staging.web.app',
    'split-app.de',
    'web.split-app.de',
    'order-test.eurekantine.de',
  ].map((hostname) => removeWWWFromHostname(hostname)))();
