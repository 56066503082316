import React from 'react';
import Image from 'next/image';
import AppleStoreBadge from '../../images/svg/badge-apple-store.svg';
import GoogleStoreBadge from '../../images/svg/badge-google-play-store.svg';
import QRCodeVytal from '../../images/svg/qr-vytal-appstore.svg';
import { CounterVy } from '../CounterVy/CounterVy';
import styles from './LeftScreenDisplay.module.css';
import { useTranslation } from 'next-i18next';
import TRANSLATION_FILES from '../../public/locales/constants';

type LeftScreenDisplayProps = {
  isAuthenticated: boolean;
  userHasActiveContainers: boolean | undefined;
  usersUsedContainers: number;
};

export const LeftScreenDisplay: React.FC<LeftScreenDisplayProps> = ({
  isAuthenticated,
  userHasActiveContainers,
  usersUsedContainers,
}) => {
  const { t } = useTranslation(TRANSLATION_FILES.leftScreen);

  return (
    <div className={styles.vytalExplanation}>
      {isAuthenticated ? (
        userHasActiveContainers ? (
          <CounterVy savedContainersCount={usersUsedContainers} />
        ) : (
          <div className="flex flex-row items-center justify-center">
            <p className={styles.welcomeText}>{t('welcome').toUpperCase()}</p>
            <Image
              src="/img/peace-sign.svg"
              width={45}
              height={100}
              className="move10pixelsUp" // due to intrinsic issues with the peace svg, we have to hack a bit to make it fit
            />
          </div>
        )
      ) : (
        <>
          <div className={styles.initialScreenContainer}>
            <a
              href="https://vytal.org"
              target="_blank"
              className="text-center "
              rel="noreferrer"
            >
              <Image
                src="/img/Vytal-logo-claim-de-white.svg"
                width={300}
                height={100}
              />
            </a>

            <div className={styles.stepsContainer}>
              <div className={styles.stepContainerRow}>
                <p className={styles.explanationNumber}>01</p>
                <p className={styles.explanationText}>{t('borrowVyWithQr')}</p>
              </div>
              <div className={styles.stepContainerRow}>
                <p className={styles.explanationNumber}>02</p>
                <p className={styles.explanationText}>{t('enjoyFood')}</p>
              </div>
              <div className={styles.stepContainerRow}>
                <p className={styles.explanationNumber}>03</p>
                <p className={styles.explanationText}>
                  {t('cleanAndReturn14')}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.qrContainer}>
            <div>
              <p className={styles.explanationText}>{t('inAppBetter')}</p>
              <div className="h-1.5" />
              <div className="flex flex-row mr-1.5">
                <AppleStoreBadge />
                <div className="w-1.5" />
                <GoogleStoreBadge />
              </div>
            </div>
            <QRCodeVytal />
          </div>
        </>
      )}
    </div>
  );
};
