import React, { useEffect, useState } from 'react';
import ChevronDownSvg from '../../images/svg/chevron-down.svg';

type Props = {
  contentContainer: React.MutableRefObject<HTMLDivElement | null>;
  bottomRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const ScrollToLayoutBottom = ({
  contentContainer,
  bottomRef,
}: Props): JSX.Element | null => {
  const [hasScrollBar, setHasScrollBar] = useState(false);

  useEffect(() => {
    const updateState = () => {
      const el = contentContainer.current;
      // the getBoundingClientRect.height is basically the window visible to the client.
      // And el.scrollHeight is the inner content (children) height that we want to make reachable to the user
      // in case children height is greater than the current window > we set Scrollbar to true and display the scrollToBottom button
      el &&
        setHasScrollBar(el.scrollHeight > el.getBoundingClientRect().height);
    };

    updateState();

    window.addEventListener('resize', updateState);
    return () => window.removeEventListener('resize', updateState);
  }, []);

  if (!hasScrollBar) {
    return null;
  }

  return (
    <div className="scrollDownButtonWrapper">
      <div
        className="scrollDownIcon"
        onClick={() =>
          bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      >
        <ChevronDownSvg />
      </div>
    </div>
  );
};
