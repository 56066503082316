import React from 'react';
import Image from 'next/image';
import styles from './CounterVy.module.css';
import { useTranslation } from 'next-i18next';
import TRANSLATION_FILES from '../../public/locales/constants';

type CounterVyProps = {
  savedContainersCount: number;
};

export const CounterVy: React.FC<CounterVyProps> = ({
  savedContainersCount,
}) => {
  const { t } = useTranslation(TRANSLATION_FILES.leftScreen);
  return (
    <>
      <div className="flex flex-row">
        <p className={styles.number}>{savedContainersCount}</p>
        <Image
          src="/img/peace-sign.svg"
          width={60}
          height={100}
          className="move20pixelsUp" // due to intrinsic issues with the peace svg, we have to hack a bit to make it fit
        />
      </div>
      <p className="text-white move20pixelsUp">{t('packagesSaved')}</p>
    </>
  );
};
